const APIPaths = {
  apiEndPoint: "https://atvnewsonline.com/ge_front/api/",
  getVideo: "get_embed_code",
  getDefaultLists:"get_default_lists",
  getSeatsByState:"get_seats_by_state_id",
  getSeatDetails:"get_seat_details",
  getStateSeatOverall:'get_state_seat_overall',
  getParliamentOverall:'get_parliament_overall'
};

export default APIPaths;
