import React from "react";
import "./StateInfoCard.scss";
import PropTypes from "prop-types";
import { getSeatDetails } from "../../utilities/Requests";
import APIResult from "../../configs/APIResult";
import ReactHtmlParser from "react-html-parser";

class StateInfoCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      details: [],
      parliament: this.props.parliament,
      ge: this.props.ge,
    };
    this.stateID = 1;
    this.ge = 14;
    this.seatID = 653;
    this.total = 1;
    this.minHeight = 10;
    this.getSeatDetails = this.getSeatDetails.bind(this);
  }

  componentDidMount() {}

  async getSeatDetails() {
    let detailResult = await getSeatDetails(
      this.props.stateID,
      this.ge,
      this.seatID
    );
    if (detailResult.status === APIResult.SUCCESS) {
      this.setState({ details: detailResult.seat_details });
    }
  }

  convertToMonthDay(epochTime) {
    
    epochTime = parseInt(epochTime);
    
    var date = new Date(epochTime * 1000);
    var formattedDate =
      date.getUTCFullYear() +
      "年" +
      (date.getUTCMonth() + 1) +
      "月" +
      date.getDate() +
      "日 " +
      date.getHours() + ":" + date.getMinutes();
    return formattedDate;
  }

  getBars(count, allInfo) {
    var maxVotes = 1;
    allInfo.forEach((eachCandidate) => {
      maxVotes = Math.max(eachCandidate.votes, maxVotes);
    });
    var finalWidth = 0;
    if (count !== undefined && count !== null && !isNaN(count) && count > 0) {
      finalWidth = (count / maxVotes) * 100;
    }
    if (isNaN(finalWidth)) {
      finalWidth = 0;
    }

    return Math.max(finalWidth, this.minHeight) + "%";
  }

  getPartyInfo(partyID) {
    for (var i = 0; i < this.props.parties.length; i++) {
      if (this.props.parties[i].party_id === partyID) {
        return this.props.parties[i];
      }
    }
    return null;
  }

  getPartyName(partyID) {
    var partyData = this.getPartyInfo(partyID.toString());
    if (partyData !== null && partyData !== undefined) {
      return (
        partyData["name_cn"] + " (" + partyData["name_en"].split("- ")[1] + ")"
      );
    }
  }

  getInvertColor(index) {
    if (index / 2 === 1) {
      return "white";
    } else {
      return "#DFDFDF";
    }
  }

  getAllianceIDWithPartyID(partyID = 0) {
    var returnValue = 0;
    if (parseInt(partyID) !== 0) {
      for (var i = 0; i < this.props.parties.length; i++) {
        let eachParty = this.props.parties[i];
        if (parseInt(eachParty.party_id) === parseInt(partyID)) {
          return parseInt(eachParty.alliance_id);
        }
      }
    }
    return returnValue;
  }

  getPollTagColor(allianceID) {
    for (var i = 0; i < this.props.alliances.length; i++) {
      if (
        parseInt(this.props.alliances[i]["alliance_id"]) ===
        parseInt(allianceID)
      ) {
        return `#${this.props.alliances[i]["color"]}`;
      }
    }
    return "#00000080";
  }

  render() {
    return (
      <div id="state-info-grid" className="state-info-grid">
        {this.props.parliament
          .sort((a, b) => Math.max(a.win - b.win, 0))
          .map((parliament, index) => {
            return (
              <div key={index}>
                <div className="state-info-card-parent">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      id={`state-info-
                        ${parliament.p_id}-
                        ${parliament.name_en}-
                        ${parliament.name_cn}`}
                      className="state-info-card-top-bg"
                      style={{
                        border: "1px solid rgba(0,0,0,0.25)",
                        boxSizing: "border-box",
                      }}
                    >
                      <div
                        style={{
                          padding: 16,
                          paddingBottom: 0,
                          fontSize: "20px",
                          fontWeight: "700",
                          color: "white",
                        }}
                      >
                        {parliament.p_id}
                        {parliament.n_id}&nbsp;&nbsp;&nbsp;
                        {parliament.name_cn
                          ? parliament.name_cn +
                            (" (" + parliament.name_en + ")")
                          : parliament.name_en}
                      </div>

                      <div
                        className="state-info-percentage-sub-title"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          padding: 16,
                          paddingTop: 8,
                          borderRadius: 12,
                        }}
                      >
                        巫裔 {parseInt(parliament["demographic"]["malay"])}%,
                        华裔 {parseInt(parliament["demographic"]["chinese"])}%,
                        印裔 {parseInt(parliament["demographic"]["indian"])}%,
                        其他 {parseInt(parliament["demographic"]["others"])}%,
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-around",
                        borderRadius: 12,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: 16,
                        }}
                      >
                        <div className="state-info-mini-title">总票数</div>
                        <div
                          style={{
                            fontWeight: 600,
                            fontSize: 16,
                            color: "black",
                          }}
                        >
                          {parliament.registered_voters}
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="state-info-mini-title">废票</div>
                        <div
                          style={{
                            fontWeight: 600,
                            fontSize: 16,
                            color: "black",
                          }}
                        >
                          {parliament.spoilt_votes}
                        </div>
                      </div>
                    </div>
                    <div className="non-official-bar">
                    {Number(parliament.official) === 0 ? (
                      <>
                        {this.props.ge === 14 ? (
                          "非官方成绩" 
                        ) : (
                          <>
                            {Math.floor(Date.now() / 1000) > 1668787200 ? (
                              "非官方成绩"
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </>
                    ) : null}
                    {Date.parse(parliament.last_modified) / 1000 > 1668787200 ? <> {" | 最后更新时间：" + parliament.last_modified}</> : "" }</div>
                    {parliament["candidates"].map((candidate, index) => {
                      return (
                        <div
                          id={
                            "candidate-block_" +
                            candidate.name_en +
                            "_" +
                            candidate.name_cn
                          }
                          className="state-info-candidate-block"
                          key={index}
                          style={{
                            background:
                              index % 2 === 0
                                ? "rgb(223, 223, 223)"
                                : "#FFFFFF",
                            borderBottomLeftRadius:
                              index === parliament["candidates"].length - 1
                                ? 12
                                : 0,
                            borderBottomRightRadius:
                              index === parliament["candidates"].length - 1
                                ? 12
                                : 0,
                          }}
                        >
                          <div
                            style={{
                              fontSize: "16",
                              color: "black",
                              fontWeight: 700,
                              padding: 16,
                              width: "35%",
                            }}
                          >
                            {candidate.name_cn}
                            <br />
                            {candidate.name_en}
                            <div className="stateinfo_party">
                              {ReactHtmlParser(
                                this.getPartyName(candidate.party_id)
                              )}
                            </div>
                          </div>
                          <div
                            style={{
                              width: "-webkit-fill-available",
                            }}
                          >
                            <div
                              className="candidate-score-bar"
                              style={{
                                background: this.getPollTagColor(
                                  this.getAllianceIDWithPartyID(
                                    candidate.party_id
                                  )
                                ),
                                width: this.getBars(
                                  candidate.votes,
                                  parliament["candidates"]
                                ),
                              }}
                            ></div>
                          </div>
                          <div
                            style={{
                              fontSize: "16",
                              color: "black",
                              fontWeight: 700,
                              padding: 16,
                              marginLeft: " auto",
                              width: "12%",
                            }}
                          >
                            {candidate.votes}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    );
  }
}

StateInfoCard.propTypes = {
  total: PropTypes.number,
  invalidVotes: PropTypes.number,
  stateID: PropTypes.number,
  parties: PropTypes.array,
  parliament: PropTypes.array,
  alliances: PropTypes.array,
  ge: PropTypes.number,
};

StateInfoCard.defaultProps = {};

export default StateInfoCard;
