import React from "react";
import "./MainPage.scss";
// import Logo from "../../images/atv_ge15_logo.png";
import BannerBackdrop from "../../images/banner_backdrop_b@4x.png";
import BannerFacebook from "../../images/banner_fb@4x.png";
import BannerInstagram from "../../images/banner_ig.svg";
// import BannerInfo from "../../images/banner_info.svg"; import
// BannerLatestMessage from "../../images/banner_latest_msg.svg";
import BannerLive from "../../images/banner_live.svg";
import BannerLogo from "../../images/banner_logo.svg";
import BannerTitle from "../../images/banner_title_b.svg";
import BannerYoutube from "../../images/banner_yt@4x.png";
import FeedsIcon from "../../images/feeds_icon.png";
import ChartInfo from "../widgets/ChartInfo";
import TableInfo from "../widgets/TableInfo";
import PollTag from "../widgets/PollTag";
import StateSeatTableInfo from "../widgets/StateSeatTableInfo";
import StateInfoCard from "../widgets/StateInfoCard";
import NotificationCard from "../widgets/NotificationCard";
import {
  getDefaultLists,
  getParliamentOverall,
  getSeatDetails,
  getSeatsByState,
  getVideo,
} from "../../utilities/Requests";
import APIResult from "../../configs/APIResult";
import DownIcon from "../../images/down_icon_grey.svg";
import RightIcon from "../../images/right_icon_grey.svg";
import { doc, onSnapshot } from "firebase/firestore";
import TagManager from "react-gtm-module";
import { Ad } from "react-ad-manager";
import queryString from "query-string";
import { gaEvent, filterSmallText } from "../../utilities/Utilities";
import InteractiveMap from "../widgets/InteractiveMap";
import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";
import FeedsPage from "./FeedsPage";
import firestoreDB from "../../utilities/Firebase";

const SearchTextBox = styled(TextField)(() => ({
  color: "white",
  border: "unset",
  backgroundColor: "white",
  borderRadius: 2,
  "&:hover": {
    backgroundColor: "white",
    border: "unset",
  },
  height: "fit-content",
}));

class MainPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultLists: [],
      alliances: [],
      states: [],
      parties: [],
      showMoreDetails: false,
      currentState: 1,
      currentStateName: "柔佛",
      mobileMode: true,
      width: 0,
      height: 0,
      currentNav: 1,
      showMobileFeed: false,
      currentParliament: [],
      chartData: [],
      newChartDataSet: [],
      newChartColors: ["#C73835", "#000080", "#008800", "#95CBED", "#625663"],
      newChartLabels: ["希盟", "国阵", "和谐阵线", "沙巴联合阵线", "独立"],
      stateOverallData: [],
      overallParliamentData: [],
      overallParliamentData2: [],
      parliamentData: [],
      stateData: [],
      mergedData: {},
      isStateDataLoading: false,
      firebaseStateData: {},
      navOn: 0,
      stateParliamentData: {},
      ge: 15,
      summarizeResult: {},
      isFeeds: false,
      lastChartResultTime: 0,
    };

    this.navButtons = [
      {
        id: 1,
        label: "视频",
        selected: false,
      },
      {
        id: 2,
        label: "执政分布图",
        selected: true,
      },
      {
        id: 3,
        label: "国州成绩",
        selected: false,
      },
    ];
    this.supportedGE = [14, 15];
    this.states = [];
    this.alliances = [];
    this.parties = [];
    this.threshold = 1024;
    this.detail = [];
    this.minHeight = 10;
    this.ge = 15;

    this.nav1 = React.createRef();
    this.nav2 = React.createRef();
    this.nav3 = React.createRef();

    this.lastStateUpdate = 1;
    this.totalPartyID = [];
    this.sortedTotalPartyID = [];

    this.t = "";

    this.onPageScroll = this.onPageScroll.bind(this);
  }

  componentDidMount() {
    let params = queryString.parse(window.location.search);
    if (params["feeds"]) {
      this.setState({ isFeeds: true });
    }
    if (params["c"]) {
      try {
        this.ge = parseInt(params["c"]);
        gaEvent("enter", `ge${this.ge}`, this.ge.toString());
        if (this.ge === 15) {
          this.setState({
            showMoreDetails: true,
          });
        }
      } catch (e) {}
    } else {
      this.setState({
        showMoreDetails: true,
      });
    }
    this.initialize();
  }

  async initialize() {
    window.addEventListener("resize", this.updateDimensions);
    this.updateDimensions();
    this.setup();
    this.updateLiveSeat();
    this.updateSummarizeResult();
    this.getSeatByID();
    TagManager.initialize({ gtmId: "G-VWS8PTK4SN" });
  }

  updateNavBarPosition(index) {
    this.setState({ navOn: index });
  }

  processOverallParliamentSeat(rawData, allData = false) {
    var parliamentData = [];
    Object.keys(rawData).forEach((eachParliamentSeat) => {
      if (rawData[eachParliamentSeat].length > 0) {
        if (
          parseInt(rawData[eachParliamentSeat][0].state_id) ===
            parseInt(this.state.currentState) ||
          allData
        ) {
          var maxVotes = 0;
          rawData[eachParliamentSeat].forEach((eachParliamentContester) => {
            if (
              Object.keys(parliamentData).includes(
                eachParliamentContester.party_id
              )
            ) {
              parliamentData[eachParliamentContester.party_id]["party_id"] =
                eachParliamentContester.party_id;
              parliamentData[eachParliamentContester.party_id]["contest"] += 1;
            } else {
              parliamentData[eachParliamentContester.party_id] = {
                party_id: eachParliamentContester.party_id,
                contest: 1,
                win: 0,
                info: {
                  party: this.getPartyInfo(eachParliamentContester.party_id),
                  alliance: this.getAllianceInfo(
                    eachParliamentContester.alliance_id
                  ),
                },
              };
            }
            maxVotes = Math.max(maxVotes, eachParliamentContester["votes"]);
          });
          rawData[eachParliamentSeat].forEach((eachParliamentContester) => {
            if (
              maxVotes === eachParliamentContester["votes"] &&
              eachParliamentContester["votes"] > 0
            ) {
              parliamentData[eachParliamentContester.party_id]["win"] += 1;
            } else {
            }
          });
        }
      }
    });
    var filteredData = [];
    parliamentData.forEach((eachP) => {
      if (eachP) {
        filteredData.push(eachP);
      }
    });
    return filteredData;
  }

  processStateParliamentData(rawData) {
    var parliamentData = {};

    this.states.forEach((eachState) => {
      var allAlliances = {};
      this.alliances.forEach((eachAlliance) => {
        allAlliances[[eachAlliance.alliance_id]] = 0;
      });
      parliamentData[[eachState.state_id]] = allAlliances;
    });
    Object.keys(rawData).forEach((eachParliamentSeat) => {
      if (rawData[eachParliamentSeat].length > 0) {
        var maxVotes = 0;
        rawData[eachParliamentSeat].forEach((eachParliamentContester) => {
          maxVotes = Math.max(maxVotes, eachParliamentContester["votes"]);
        });
        rawData[eachParliamentSeat].forEach((eachParliamentContester) => {
          if (maxVotes === eachParliamentContester["votes"]) {
            parliamentData[eachParliamentContester.state_id][
              eachParliamentContester.alliance_id
            ] =
              parliamentData[eachParliamentContester.state_id][
                eachParliamentContester.alliance_id
              ] + 1;
          }
        });
      }
    });

    return parliamentData;
  }

  async getAllSeatOverallData() {
    this.setState({ isStateDataLoading: true });
    let parliamentResult = await getParliamentOverall(this.ge);
    let stateResult = [];
    if (
      Object.keys(this.state.firebaseStateData).includes(
        `${this.ge}-${this.state.currentState}`
      )
    ) {
      var dataToDecipher =
        this.state.firebaseStateData[`${this.ge}-${this.state.currentState}`];
      Object.keys(dataToDecipher).forEach((eachParty) => {
        stateResult.push(dataToDecipher[eachParty]);
      });
    }

    if (parliamentResult.status === APIResult.SUCCESS) {
      let parliamentData = this.processOverallParliamentSeat(
        parliamentResult["data"]["raw"]
      );
      let allParliamentData = this.processOverallParliamentSeat(
        parliamentResult["data"]["raw"],
        true
      );
      let stateParliamentData = this.processStateParliamentData(
        parliamentResult["data"]["raw"]
      );
      this.processChartData(allParliamentData);

      var mergedData = {};
      parliamentData.forEach((eachParliament) => {
        if (
          !Object.keys(mergedData).includes(
            eachParliament["party_id"].toString()
          )
        ) {
          mergedData[eachParliament["party_id"]] = {
            par_contest: parseInt(eachParliament["contest"]),
            par_win: parseInt(eachParliament["win"]),
            state_contest: 0,
            state_win: 0,
          };
        }
      });
      stateResult.forEach((eachState) => {
        if (
          !Object.keys(mergedData).includes(eachState["party_id"].toString())
        ) {
          mergedData[eachState["party_id"]] = {
            par_contest: 0,
            par_win: 0,
            state_contest: parseInt(eachState["contest"]),
            state_win: parseInt(eachState["win"]),
          };
        } else {
          mergedData[eachState["party_id"]]["state_contest"] = parseInt(
            eachState["contest"]
          );
          mergedData[eachState["party_id"]]["state_win"] = parseInt(
            eachState["win"]
          );
        }
      });

      this.setState({
        parliamentData: allParliamentData,
        stateData: stateResult["contest_data"],
        mergedData: mergedData,
        isStateDataLoading: false,
        stateParliamentData: stateParliamentData,
      });
    }
  }

  processChartData(input) {
    var allianceChartData = {};
    for (var i = 0; i < input.length; i++) {
      let alliance_id = input[i].info.alliance.alliance_id;
      if (!Object.keys(allianceChartData).includes(alliance_id)) {
        allianceChartData[alliance_id] = {
          alliance_id: alliance_id,
          win: 0,
          label: input[i].info.alliance.name_cn,
        };
      }
      allianceChartData[alliance_id].win += input[i].win;
    }

    var sortedAllianceID = Object.keys(allianceChartData).sort(
      (a, b) => allianceChartData[b].win - allianceChartData[a].win
    );
    var dataSets = [];
    var dataLabels = [];
    sortedAllianceID.forEach((eachAlliance) => {
      dataSets.push(allianceChartData[eachAlliance].win);
      dataLabels.push(allianceChartData[eachAlliance].label);
    });

    var allAlliances = [];
    var allWins = [];
    var allLabels = [];
    Object.keys(this.state.summarizeResult).forEach((eachAlliance) => {
      allAlliances.push(this.state.summarizeResult[eachAlliance]);
    });

    allAlliances.sort(function (a, b) {
      return b["win"] - a["win"];
    });
    allAlliances.forEach((eachAlliance) => {
      if (eachAlliance["win"] > 0) {
        if (
          this.state.lastChartResultTime <
          eachAlliance["last_update"] / 1000
        ) {
          this.setState({
            lastChartResultTime: eachAlliance["last_update"] / 1000,
          });
        }
      }
      allWins.push(eachAlliance["win"]);
      allLabels.push(
        (this.getAllianceInfo(eachAlliance["alliance_id"]) || {
          name_cn: "其他",
        })["name_cn"]
      );
    });

    // console.log(allWins, allLabels);
    // if (this.ge === 14) {
    //   dataSets = [113, 79, 18, 12];
    //   dataLabels = ["希盟", "国阵", "伊斯兰党", "其他"];
    // }

    this.setState({ newChartDataSet: allWins, newChartLabels: allLabels });
  }

  getBars(count, allInfo) {
    var maxVotes = 1;
    allInfo.forEach((eachCandidate) => {
      maxVotes = Math.max(eachCandidate.votes, maxVotes);
    });
    var finalWidth = 0;
    if (count !== undefined && count !== null && !isNaN(count) && count > 0) {
      finalWidth = (count / maxVotes) * 100;
    }
    if (isNaN(finalWidth)) {
      finalWidth = 0;
    }

    return Math.max(finalWidth, this.minHeight) + "%";
  }

  async setup() {
    this.getVideoIframe();
    await this.getLists();
    this.getAllSeatOverallData();
  }

  async getVideoIframe() {
    let result = await getVideo();
    if (
      result.status === APIResult.SUCCESS &&
      document.getElementById("embed-container")
    ) {
      document.getElementById("embed-container").innerHTML = result.embed_code;
    } else if (result.status === APIResult.FAILED) {
    }
  }

  async getSeatByID() {
    let result = await getSeatsByState(this.state.currentState, this.ge, true);
    if (result.status === APIResult.SUCCESS) {
      this.setState({ currentParliament: result["parliament"] });
    }
  }

  getAllianceIDWithPartyID(partyID = 0) {
    var returnValue = "0";
    if (parseInt(partyID) !== 0) {
      for (var i = 0; i < this.parties.length; i++) {
        let eachParty = this.parties[i];
        if (parseInt(eachParty.party_id) === parseInt(partyID)) {
          return eachParty.alliance_id;
        }
      }
    }
    return returnValue;
  }

  getPartyInfo(partyID) {
    for (var i = 0; i < this.parties.length; i++) {
      if (parseInt(this.parties[i].party_id) === parseInt(partyID)) {
        return this.parties[i];
      }
    }
    return null;
  }

  getAllianceInfo(allianceID) {
    for (var i = 0; i < this.alliances.length; i++) {
      if (parseInt(this.alliances[i].alliance_id) === parseInt(allianceID)) {
        return this.alliances[i];
      }
    }
    return null;
  }

  scrollToSection(id) {
    // var number1 = parseInt(document.getElementById("number1").offsetTop);
    // var number2 = parseInt(document.getElementById("number2").offsetTop);
    // var number3 = parseInt(document.getElementById("number3").offsetTop);
    // var scrollTo = 0;
    // if (id === 1) {
    //   scrollTo = number2 - number1;
    // }
    // if (id === 2) {
    //   scrollTo = number3 - number1;
    // }
    // document
    //   .getElementById("scroll-parent")
    //   .scrollTo({ top: scrollTo, behavior: "smooth" });
    if (id === 0) {
      document.getElementById("latest-video").scrollIntoView({
        behavior: "smooth",
      });
    }
    if (id === 1) {
      document.getElementById("ge-score").scrollIntoView({
        behavior: "smooth",
      });
    }
    if (id === 2) {
      document.getElementById("detail-score").scrollIntoView({
        behavior: "smooth",
      });
    }
  }

  async getSeatDetails(seatID) {
    let result = await getSeatDetails(this.state.currentState, this.ge, seatID);
    if (result.status === APIResult.SUCCESS) {
      this.setState({ detail: result });
    }
  }

  updateSummarizeResult() {
    onSnapshot(
      doc(firestoreDB, `ge${this.ge}`, "live-summarize-result"),
      (doc) => {
        if (doc.data() !== undefined) {
          this.setState({ summarizeResult: doc.data() });
        }
      }
    );
  }

  updateLiveSeat() {
    onSnapshot(doc(firestoreDB, `ge${this.ge}`, "live-track"), (doc) => {
      if (doc.data() !== undefined) {
        this.getSeatDetails(doc.data()["seat_id"]);
      }
    });

    onSnapshot(doc(firestoreDB, `ge${this.ge}`, "live-state-seat"), (doc) => {
      if (doc.data() !== undefined) {
        this.setState(
          {
            firebaseStateData: doc.data(),
          },
          this.getAllSeatOverallData
        );
      }
    });
  }

  updateLiveState() {
    onSnapshot(doc(firestoreDB, `ge${this.ge}`, "live-track-state"), (doc) => {
      if (doc.data() !== undefined) {
        if (
          doc.data()["state_id"] === this.state.currentState &&
          this.lastStateUpdate !== doc.data()["time"]
        ) {
          this.lastStateUpdate = doc.data()["time"];
        }
      }
    });
  }

  async getLists() {
    let result = await getDefaultLists(this.ge);
    if (result.status === APIResult.SUCCESS) {
      this.setState({
        states: result["lists"]["states"],
        alliances: result["lists"]["alliances"],
        parties: result["lists"]["parties"],
        currentState: parseInt(result["lists"]["states"][0].state_id),
      });

      this.states = result["lists"]["states"];
      this.alliances = result["lists"]["alliances"];
      this.parties = result["lists"]["parties"];
    }
  }

  updateState(stateID) {
    this.setState(
      {
        currentState: parseInt(stateID),
        currentStateName: this.state.states[stateID - 1].name_cn,
      },

      () => {
        this.getSeatByID();
        this.getAllSeatOverallData();
      }
    );
    //console.log("state : " + this.state.states[stateID - 1].name_cn);
    document.getElementById("select-state-section").scrollIntoView({
      behavior: "smooth",
    });
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
    if (window.innerWidth < this.threshold) {
      this.setState({ mobileMode: true });
    } else {
      this.setState({ mobileMode: false });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  goToPage(url) {
    window.open(url, "_blank", "noopener,noreferrer");
  }

  onPageScroll(event) {
    let offsetTop = event.currentTarget.scrollTop;
    var number1 = parseInt(document.getElementById("number1").offsetTop);
    var number2 = parseInt(document.getElementById("number2").offsetTop);
    var number3 = parseInt(document.getElementById("number3").offsetTop);

    var id = 0;
    var gap = -1;
    [number1, number2, number3].forEach((eachNumber, index) => {
      var tempGap = Math.abs(eachNumber - offsetTop - number1);
      if (gap === -1) {
        gap = tempGap;
      }
      if (tempGap > 0) {
      }
      gap = Math.min(gap, tempGap);
      if (gap === tempGap) {
        id = index;
      }
    });

    // console.log(number1, number2, number3, "--", gap, id);

    if (id !== this.state.navOn) {
      this.setState({ navOn: id });
      gaEvent(
        "scroll-reach",
        `ge${this.ge}`,
        `ge${this.ge}-${this.navButtons[id].label}`
      );
    }
  }

  findPosition(obj) {
    var currenttop = 0;
    if (obj.offsetParent) {
      do {
        currenttop += obj.offsetTop;
      } while ((obj = obj.offsetParent));
      return [currenttop];
    }
  }

  quickScroll(target) {
    if (!this.state.showMoreDetails) {
      this.setState({
        showMoreDetails: !this.state.showMoreDetails,
      });
    }
    var ids = document.querySelectorAll(
      "div.state-info-card-top-bg,div.state-info-candidate-block"
    );
    var BreakException = {};
    try {
      ids.forEach((eachElement) => {
        if (filterSmallText(eachElement.id).includes(filterSmallText(target))) {
          //   var divTop = document.getElementById("scroll-parent").offsetTop;
          //   var elementTop = document.getElementById(eachElement.id).offsetTop;
          //   var elementRelativeTop = elementTop - divTop;
          //console.log("divTop " + divTop + " eachElement.id " + eachElement.id + " elementTop " +  elementTop + " elementRelativeTop " + elementRelativeTop);
          //document.getElementById("scroll-parent").scrollTo(0, elementTop);
          document.getElementById(eachElement.id).scrollIntoView();

          throw BreakException;
        }
      });
    } catch (e) {
      if (e !== BreakException) throw e;
    }
  }

  render() {
    return (
      <div>
        {this.state.isFeeds ? (
          <FeedsPage />
        ) : (
          <div
            className="parent center-h full-width"
            style={{ overflowX: "hidden", height: this.props.fullHeight }}
          >
            <div
              className="parent center-h full-width"
              style={{
                alignItems: "center",
                position: "sticky",
                top: 0,
                boxSizing: "border-box",
                zIndex: 100,
              }}
            >
              <div className="main-page-cover-image">
                <img
                  alt="main-page-logo"
                  id="main-page-cover"
                  className="main-page-cover-image"
                  src={BannerBackdrop}
                />
                <div className="center-all absolute">
                  <img
                    alt="main-page-logo"
                    id="main-page-logo"
                    style={{
                      maxWidth: "75%",
                    }}
                    height={"75%"}
                    src={BannerTitle}
                  />
                </div>
                <div
                  className="bottom-right-all absolute"
                  style={{
                    padding: 4,
                    boxSizing: "border-box",
                    paddingRight: 0,
                  }}
                >
                  <img
                    alt="main-page-logo"
                    onClick={() => {
                      this.goToPage("https://www.facebook.com/asiatvnewssea");
                      gaEvent("button", `social`, "facebook");
                    }}
                    className="media-button"
                    src={BannerFacebook}
                  />
                  <img
                    alt="main-page-logo"
                    onClick={() => {
                      this.goToPage("https://www.instagram.com/asiatvnewssea");
                      gaEvent("button", `social`, "instagram");
                    }}
                    className="media-button"
                    src={BannerInstagram}
                  />
                  <img
                    alt="main-page-logo"
                    onClick={() => {
                      this.goToPage("https://www.youtube.com/asiatvnewssea");
                      gaEvent("button", `social`, "youtube");
                    }}
                    className="media-button"
                    src={BannerYoutube}
                  />
                  <img
                    alt="main-page-logo"
                    onClick={() => {
                      this.goToPage(
                        "https://www.facebook.com/asiatvnewssea/videos"
                      );
                      gaEvent("button", `social`, "live");
                    }}
                    className="media-button"
                    src={BannerLive}
                  />
                </div>
                <div
                  className="top-left-all absolute"
                  style={{
                    pointerEvents: "none",
                    justifyContent: "space-between",
                  }}
                >
                  <img
                    alt="main-page-logo"
                    onClick={() => {
                      this.goToPage("https://www.atvnewsonline.com");
                      gaEvent("button", `social`, "offical-site");
                    }}
                    style={{
                      width: 200,
                      maxWidth: "25%",
                      paddingTop: 16,
                      paddingLeft: 16,
                      cursor: "pointer",
                      pointerEvents: "all",
                    }}
                    src={BannerLogo}
                  />
                </div>
              </div>
              <div className="main-page-nav-buttons" id="style-2">
                {this.ge > 14 ? (
                  <div className="rel">
                    <div
                      className="nav-button"
                      style={{
                        minWidth: 105,
                        textAlign: "center",
                        fontWeight: 700,
                      }}
                      onClick={() => {
                        gaEvent("navigate", `main`, `回顾第${this.ge - 1}届`);
                        window.location.href =
                          "/?c=" + (this.ge - 1).toString();
                      }}
                    >
                      回顾第{this.ge - 1}届
                    </div>
                  </div>
                ) : null}
                {this.navButtons.map((eachPage, index) => {
                  return (
                    <div className="rel" key={index}>
                      <div
                        className={`nav-button ${
                          index === this.state.navOn ? "nav-button-after" : ""
                        }`} // className="nav-button"
                        style={{
                          minWidth: 80,
                          textAlign: "center",
                        }}
                        onClick={() => {
                          this.scrollToSection(index);
                          this.updateNavBarPosition(index);
                          gaEvent("navigate", `main`, eachPage.label);
                        }}
                      >
                        {eachPage.label}
                      </div>
                    </div>
                  );
                })}
                <div className="rel">
                  <div
                    className="nav-button"
                    style={{
                      minWidth: 60,
                      textAlign: "center",
                    }}
                    onClick={() => {
                      gaEvent("navigate", `main`, "选区探温");
                      window.open(
                        "https://atvnewsonline.com/tag/%E9%80%89%E5%8C%BA%E6%8E%A2%E6%B8%A9/",
                        "_blank"
                      );
                    }}
                  >
                    选区探温
                  </div>
                </div>
                <div className="rel">
                  <div
                    className="nav-button"
                    style={{
                      minWidth: 60,
                      textAlign: "center",
                    }}
                    onClick={() => {
                      gaEvent("navigate", `main`, "HiYB");
                      window.open(
                        "https://atvnewsonline.com/tag/HIYB/",
                        "_blank"
                      );
                    }}
                  >
                    Hi，YB！
                  </div>
                </div>
                {this.ge === 14 ? (
                  <div className="rel">
                    <div
                      className="nav-button"
                      style={{
                        minWidth: 105,
                        textAlign: "center",
                        fontWeight: 700,
                      }}
                      onClick={() => {
                        gaEvent("navigate", `main`, "关注第15届");
                        window.location.href = "/?c=15";
                      }}
                    >
                      关注第15届
                    </div>
                  </div>
                ) : null}
              </div>
              <hr
                style={{
                  backgroundColor: "#00000040",
                  height: "1px",
                }}
              ></hr>
            </div>

            <div
              className="hori bottom no-scrollbar"
              style={{
                position: "relative",
                overflowX: "hidden",
                overflowY: "scroll",
                height: `calc(${this.props.fullHeight}px - 20vh - 49.5px)`,
              }}
            >
              <div
                id="parent"
                className={
                  this.state.mobileMode
                    ? "main-page-mobile-parent no-scrollbar"
                    : "flex-h full-width max-constraint center-h no-scrollbar"
                }
              >
                <div
                  id="style-2"
                  className="left-constraint show-scroll"
                  onScroll={this.onPageScroll}
                  style={{
                    paddingRight: this.state.mobileMode ? "0" : "1em",
                    height: "100%",
                    width: "100%",
                    overflowY: "scroll",
                    overflowX: "hidden",
                  }}
                >
                  <div
                    id="number1"
                    className="main-page-video"
                    style={{
                      padding: 0,
                      borderRadius: 8,
                      aspectRatio: "16/9",
                    }}
                  >
                    <div id="latest-video" className="main-page-title">
                      最新视频
                    </div>
                    <div id="embed-container" className="center-flex" />
                  </div>
                  <div
                    id="number2"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {this.ge === 14 ||
                    ((this.ge === 15 || this.ge === 16) &&
                      this.state.lastChartResultTime > 0) ? (
                      <div
                        className={`main-page-chart-info-${
                          this.state.mobileMode ? "mobile" : "desktop"
                        }`}
                      >
                        <div id="ge-score" className="main-page-title">
                          第{this.ge}届马来西亚选举成绩
                        </div>
                        <ChartInfo
                          ge={this.ge}
                          overallData={this.state.chartData}
                          chartDataSet={this.state.newChartDataSet}
                          chartLabels={this.state.newChartLabels}
                          chartColors={this.state.newChartColors}
                          lastUpdated={this.state.lastChartResultTime}
                        />
                      </div>
                    ) : null}
                  </div>
                  {this.ge === 14 || this.ge === 15 || this.ge === 16 ? (
                    <div style={{}} className="main-page-table-info v-gap-m">
                      <TableInfo
                        parties={this.state.parties}
                        alliances={this.state.alliances}
                        parliamentData={this.state.parliamentData}
                      />
                    </div>
                  ) : null}
                  <div className="ads-parent">
                    <Ad
                      adUnit="/22439786158/content_mid1"
                      name="div-gpt-ad-1664963683758-0"
                      collapseEmptyDivs={true}
                      size={[[300, 250], [336, 280], "fluid"]}
                    />
                  </div>
                  <div
                    style={{}}
                    id="number3"
                    className="main-page-state-seats"
                  >
                    <div id="detail-score" className="main-page-title">
                      国州席
                    </div>
                    <InteractiveMap
                      ge={this.ge}
                      mobileMode={this.state.mobileMode}
                      alliances={this.state.alliances}
                      stateParliamentData={this.state.stateParliamentData}
                      currentState={this.state.currentState}
                      selectState={(e) => this.updateState(e)}
                    />
                    <div
                      id="select-state-section"
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      {this.state.states.map((state, index) => {
                        return (
                          <div
                            style={{
                              padding: 8,
                              boxSizing: "border-box",
                              cursor: "pointer",
                            }}
                            key={index}
                          >
                            <PollTag
                              disabled={
                                parseInt(this.state.currentState) ===
                                parseInt(state.state_id)
                                  ? false
                                  : true
                              }
                              border={
                                parseInt(this.state.currentState) ===
                                parseInt(state.state_id)
                                  ? "2"
                                  : "0"
                              }
                              label={state.name_cn}
                              callback={() => this.updateState(state.state_id)}
                              width={64}
                              fontWeight={500}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    style={
                      this.state.mobileMode
                        ? {
                            height: "100%",
                            paddingBottom: 0,
                          }
                        : {
                            height: "100%",
                          }
                    }
                  >
                    {this.ge === 14 || this.ge === 15 ? (
                      <div
                        style={{
                          marginTop: 16,
                          borderRadius: 8,
                        }}
                      >
                        <StateSeatTableInfo
                          currentState={this.state.currentState}
                          parties={this.state.parties}
                          alliances={this.state.alliances}
                          isLoading={this.state.isStateDataLoading}
                          overallData={this.state.mergedData}
                        />
                      </div>
                    ) : null}
                    <div
                      onClick={() => {
                        this.setState({
                          showMoreDetails: !this.state.showMoreDetails,
                        });
                        gaEvent("button", `table`, "state_data");
                      }}
                      className="main-page-more-details"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexBasis: "1 0 21%",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      更多详情
                      <img
                        alt="show-more-items-icon"
                        src={!this.state.showMoreDetails ? RightIcon : DownIcon}
                      />
                    </div>

                    {this.state.showMoreDetails ? (
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: this.state.mobileMode
                            ? ""
                            : "repeat(auto-fit, minmax(450px, 1fr))",
                          gap: "1em",
                        }}
                      >
                        <StateInfoCard
                          stateID={this.state.currentState}
                          parties={this.state.parties}
                          alliances={this.state.alliances}
                          parliament={this.state.currentParliament}
                          ge={this.ge}
                        />
                      </div>
                    ) : null}
                  </div>
                  <div
                    style={{
                      paddingBottom: this.state.mobileMode ? 196 : 0,
                      zIndex: 200,
                    }}
                  />{" "}
                </div>
              </div>

              {!this.state.mobileMode ? (
                <div className="updates-parent">
                  <div className="main-page-title">选战焦点</div>
                  <NotificationCard desktopMode={true} />
                </div>
              ) : null}

              <div>
                <div className="fader"></div>
              </div>
            </div>
            {this.state.mobileMode ? (
              <div className="absolute-bottom bottom-bar bottom-dark-fade"></div>
            ) : null}
            {this.state.mobileMode ? (
              <div
                className="absolute-bottom bottom-bar"
                style={{ marginBottom: "1em" }}
              >
                <div className="search-bar">
                  <div
                    className="find-state"
                    onClick={() => {
                      document.getElementById("semenanjung").scrollIntoView({
                        behavior: "smooth",
                      });
                    }}
                  >
                    州属
                  </div>
                  <SearchTextBox
                    onChange={(e) => {
                      this.quickScroll(e.target.value);
                    }}
                    fullWidth
                    id="outlined-required"
                    label={
                      `快速搜寻` + this.state.currentStateName + `国席&候选人`
                    }
                    defaultValue=""
                  />
                </div>
                <div
                  onClick={() => {
                    this.setState({ showMobileFeed: true });
                  }}
                  style={{
                    overflow: "hidden",
                    height: "100%",
                    width: 96,
                  }}
                >
                  <img
                    className="bottom-right-shortcut"
                    src={FeedsIcon}
                    alt=""
                  />
                </div>
              </div>
            ) : (
              <div
                className="absolute-bottom bottom-bar"
                style={{
                  marginBottom: "1em",
                  width: 360,
                  left: "50%",
                  marginLeft: -100,
                  position: "absolute",
                }}
              >
                {this.state.navOn >= 2 ? (
                  <div className="search-bar" style={{}}>
                    <div
                      className="find-state"
                      onClick={() => {
                        document.getElementById("semenanjung").scrollIntoView({
                          behavior: "smooth",
                        });
                      }}
                    >
                      州属
                    </div>
                    <SearchTextBox
                      onChange={(e) => {
                        this.quickScroll(e.target.value);
                      }}
                      fullWidth
                      id="outlined-required"
                      label={
                        `快速搜寻` + this.state.currentStateName + `国席&候选人`
                      }
                      defaultValue=""
                    />
                  </div>
                ) : null}
              </div>
            )}
            {this.state.showMobileFeed && this.state.mobileMode ? (
              <div
                className="main-page-notification"
                style={{
                  left: 0,
                  right: 0,
                  borderTopLeftRadius: "1em",
                  borderTopRightRadius: "1em",
                  overflowX: "hidden",
                  height: "calc(100%-70px)",
                }}
              >
                <NotificationCard
                  disableMobile={() => {
                    this.setState({ showMobileFeed: false });
                  }}
                  desktopMode={false}
                />
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

export default MainPage;
