import ReactGA from "react-ga4";

export function debugPrint(e) {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    console.log(e);
  }
}
export function checkIfStringEmpty(input) {
  return input.trim() === "";
}
export function filterText(s) {
  return s
    .toLowerCase()
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]/g, "");
}
export function filterSmallText(s) {
  return s.toLowerCase();
}
export function calcMaxNum(array) {
  return Math.max.apply(null, array);
}

export function calcMinNum(array) {
  return Math.min.apply(null, array);
}

export function isValidMonth(month) {
  return month !== "total" && month.startsWith("m-");
}

export function epochToDateTime(epochTime) {
  var date = new Date(epochTime * 1000);
  var iso = date.toISOString().match(/(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2})/);
  return iso[1] + " " + iso[2];
}

export function epochToDate(epochTime) {
  var date = new Date(epochTime * 1000);
  var iso = date.toISOString().match(/(\d{4}-\d{2}-\d{2})/);
  return iso[1];
}

export function getImagePath(path) {
  if (path === null || path === undefined) {
    return "";
  }
  if (path.trim() === "") {
    return "https://ezimart.shop/pics/no_image.png";
  } else {
    if (path.startsWith("http")) {
      return path;
    } else {
      return `https://ezimart.shop/${path}`;
    }
  }
}
export async function resizeImage(file) {
  return file;
}

export function checkImageAspectRatio(
  file,
  aspectRatio = 0,
  successCallback,
  failedCallback
) {
  if (aspectRatio === 0) {
    successCallback();
    return true;
  }
  try {
    var reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = function (e) {
      var image = new Image();
      image.src = e.target.result;
      image.onload = function () {
        var height = this.height;
        var width = this.width;
        var currentAspectRatio = width / height;
        if (
          currentAspectRatio > aspectRatio * 0.9 &&
          currentAspectRatio < aspectRatio * 1.1
        ) {
          successCallback();
          return true;
        }
        failedCallback();
        return false;
      };
    };
  } catch (e) {
    failedCallback();
    return false;
  }
}

export function getBase64(file, cb) {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (error) {
    debugPrint(("Error: ", error));
  };
}
export function getStoredInfo(key) {
  const ezmData = localStorage.getItem("ezm");
  if (ezmData !== null) {
    var ezmJson = JSON.parse(ezmData);
    if (ezmJson.hasOwnProperty(key)) {
      return {
        available: true,
        info: ezmJson[key]["info"],
        updateTime: ezmJson[key]["updateTime"],
      };
    }
  }
  return { available: false, info: null, updateTime: 0 };
}

export function showDashedValue(float) {
  if (float === 0) {
    float.toString();
    float = " - ";
  }
  return float;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function gaEvent(action, category, label) {
  ReactGA.event({ action: action, category: category, label: label });
}

export function gaSend() {
  ReactGA.send(window.location.href);
}

export function de(p) {
  for (var i = 0; i < 13; i++) {
    p = atob(p);
    // console.log("progressing", p);
  }
  //   console.log("returning", p);
  return p;
}
