import React from "react";
import "./ChartInfo.scss";
import PropTypes from "prop-types";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  rotation: 270,
  circumference: 180,
};

class ChartInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        datasets: [
          {
            // data: [1,1,1,1,1],
            backgroundColor: ["#0F74BA", "#E15235", "#3B8955", "#625663"],
            hoverBackgroundColor: ["#0F74BA", "#E15235", "#3B8955", "#625663"],
          },
        ],
      },
    };
  }
  convertToMonthDay(epochTime) {
    
    epochTime = parseInt(epochTime);
    
    var date = new Date(epochTime * 1000);
    var formattedDate =
      date.getUTCFullYear() +
      "年" +
      (date.getUTCMonth() + 1) +
      "月" +
      date.getDate() +
      "日 " +
      date.getHours() + ":" + date.getMinutes();
    return formattedDate;
  }
  componentDidMount() {}

  render() {
    return (
      <div className="chart-info-parent">
        <div
          style={{
            padding: 16,
            maxWidth: 450,
            maxHeight: 400,
            width: "75%",
          }}
        >
          <Doughnut
            width={400}
            height={100}
            data={{
              labels: this.props.chartLabels,
              datasets: [
                {
                  id: 1,
                  label: "dd",
                  data: this.props.chartDataSet,
                  backgroundColor: this.props.chartColors,
                },
              ],
            }}
            options={options}
          />
        </div>
        <div className="result-announced">
          {Number(this.props.ge) === 14 ? "*成绩公布于 2018年5月9日" : null}
          {Number(this.props.ge) === 15 ? "*更新于 " + this.convertToMonthDay(this.props.lastUpdated) : null}
        </div>
        <div
          className="chart-info-number"
          style={{
            padding: 16,
          }}
        >
          {this.props.chartLabels.map((data, index) => {
            return (
              <div
                key={index}
                style={{
                  justifySelf: index % 2 === 0 ? "end" : "start",
                  marginLeft: 10,
                  marginRight: 10,
                  justifyItems: "flex-start",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    gap: "1em",
                  }}
                >
                  <div
                    style={{
                      color: this.props.chartColors[index],
                      fontWeight: 600,
                      marginTop: 8,
                      width: "50%",
                      alignContent: "flex-end",
                      textAlign: "end",
                    }}
                  >
                    {data}
                  </div>
                  <div
                    style={{
                      color: this.props.chartColors[index],
                      fontSize: "36px",
                      fontWeight: 600,
                      justifyItems: "flex-start",
                      alignItems: "flex-start",
                      width: "50%",
                    }}
                  >
                    {`${
                      this.props.chartDataSet[index]
                        ? this.props.chartDataSet[index]
                        : 0
                    }${index === 0 ? "" : ""}`}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

ChartInfo.propTypes = {
  ge: PropTypes.number,
  label: PropTypes.string,
  callback: PropTypes.func,
  width: PropTypes.string,
  lastUpdated: PropTypes.number
};

ChartInfo.defaultProps = {
  ge: 15,
  label: "Callback",
  width: "3 px",
  lastUpdated: 0,
  callback: () => {},
};

export default ChartInfo;
