import React from 'react';
import PropTypes from 'prop-types';
import { getDefaultLists } from '../../utilities/Requests';
import APIResult from '../../configs/APIResult';

class PollTag extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        
    }
this.ge=14;
this.parties=[];
}

async getDefaultLists(){
    let result = await getDefaultLists(this.ge);
    if(result.status === APIResult.SUCCESS){
        this.parties = result["lists"]["parties"];
    }  
}



    render() {
        return (
            <div className='state-button' onClick ={this.props.callback} style={{
                background:this.props.disabled?`${this.props.color}80`:this.props.color,
                width:this.props.width,
                boxShadow:`inset 0px 0px 0px ${this.props.border}px #00000020`,
                
            }}>
                <div style={{color:"white", fontWeight:this.props.fontWeight, fontSize:"16px"}}>
                    {this.props.label}
                </div>
            </div>
            );
    }
}

PollTag.propTypes = {
  label:PropTypes.string,
  color:PropTypes.string,
  width:PropTypes.number,
  disabled:PropTypes.bool,
  fontWeight:PropTypes.number,
  callback:PropTypes.func,
  border:PropTypes.string
};

PollTag.defaultProps = {
    label:"巫统",
    color:"#2D66B2",
    width:"120px",
    disabled:false,
    border: "1px solid rgba(0, 0, 0, 0.25)",
    fontWeight:700,
    callback:function(){
    }
};

export default PollTag;