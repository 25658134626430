import APIHeader from "../configs/APIHeader";
import APIPaths from "../configs/APIPaths";
import APIResult from "../configs/APIResult";
import { debugPrint } from "./Utilities";
import { createBrowserHistory } from "history";


const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
        if (typeof value === "object" && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };
};

const obj = { address: { country: "Chile" }, numbers: [1, 2, 3], age: 30 };
obj.name = obj;

function fillUpSessionInfo(body) {
    let session = JSON.parse(localStorage.getItem("session"));
    if (session !== undefined && session !== null) {
        try {
            body["user_id"] = session["userID"];
            body["jwt"] = session["jwt"];
        } catch (error) {
            localStorage.clear("session");
            return false;
        }
    }
    return body;
}

async function callAPI(body, target, defaultReturn) {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": APIHeader.contentType,
            apikey: APIHeader.apiKey,
        },
        body: JSON.stringify(fillUpSessionInfo(body), getCircularReplacer()),
    };

    debugPrint(target);
    debugPrint(body);

    var proceed = true;

    Object.values(body).forEach((param) => {
        if (param === null || param === undefined) {
            proceed = false;
        }
    });

    if (proceed) {
        await new Promise((resolve) => setTimeout(resolve, 500));
        return await fetch(target, requestOptions)
            .then((response) => response.json())
            .then((responseData) => {
                debugPrint(responseData);
                try {
                    if (responseData.status === APIResult.INVALID_SESSION) {
                        createBrowserHistory().push("/login?to=1");
                        window.location.reload();
                    } else if (responseData.status === APIResult.INVALID) {
                        createBrowserHistory().push("/login?to=5");
                        window.location.reload();
                    }
                } catch (e) {
                    debugPrint(e);
                }
                return responseData;
            })
            .catch((error) => {
                console.warn(error);
                return defaultReturn;
            });
    } else {
        return defaultReturn;
    }
}

// async function callAPIWithXMLHR(
//     body,
//     target,
//     defaultReturn,
//     progressCallback,
//     finalCallback
// ) {
//     body = fillUpSessionInfo(body);

//     debugPrint(target);
//     debugPrint(body);

//     const xmlHR = new XMLHttpRequest();
//     await new Promise((resolve) => {
//         xmlHR.upload.addEventListener("progress", (event) => {
//             if (event.lengthComputable) {
//                 progressCallback(event.loaded / event.total);
//             }
//         });
//         xmlHR.addEventListener("progress", (event) => {
//             if (event.lengthComputable) {
//             }
//         });
//         xmlHR.addEventListener("loadend", () => {
//             resolve(xmlHR.readyState === 4 && xmlHR.status === 200);
//         });
//         xmlHR.open("POST", target, true);
//         xmlHR.setRequestHeader("Content-Type", APIHeader.contentType);
//         xmlHR.setRequestHeader("apikey", APIHeader.apiKey);
//         xmlHR.responseType = "json";
//         xmlHR.onload = function (e) {
//             if (this.status === 200) {
//                 finalCallback(this.response);
//             } else {
//                 finalCallback(defaultReturn);
//             }
//         };
//         xmlHR.send(JSON.stringify(body));
//     });
// }

export async function getVideo() {
    var body = {
        
    };
    var target = APIPaths.apiEndPoint.concat(APIPaths.getVideo);

    return callAPI(body, target, {
        status: APIResult.TIME_OUT,
        status_message: "-",
        t: [],
    });
}

export async function getDefaultLists(ge) {
    var body = {
        ge: ge
    };
    var target = APIPaths.apiEndPoint.concat(APIPaths.getDefaultLists);

    return callAPI(body, target, {
        status: APIResult.TIME_OUT,
        status_message: "-",
        t: [],
    });
}

export async function getSeatsByState(stateID,ge,details) {
    var body = {
        state_id: stateID,
        ge: ge,
        more_details: details
    };
    var target = APIPaths.apiEndPoint.concat(APIPaths.getSeatsByState);

    return callAPI(body, target, {
        status: APIResult.TIME_OUT,
        status_message: "-",
        t: [],
    });
}

export async function getSeatDetails(stateID,ge,seatID) {
    var body = {
        state_id:stateID,
        ge: ge,
        seat_id:seatID
    };
    var target = APIPaths.apiEndPoint.concat(APIPaths.getSeatDetails);

    return callAPI(body, target, {
        status: APIResult.TIME_OUT,
        status_message: "-",
        t: [],
    });
}


export async function getStateSeatOverall(stateID,ge) {
    var body = {
        state_id:stateID,
        ge: ge,
    };
    var target = APIPaths.apiEndPoint.concat(APIPaths.getStateSeatOverall);

    return callAPI(body, target, {
        status: APIResult.TIME_OUT,
        status_message: "-",
        t: [],
    });
}

export async function getParliamentOverall(ge) {
    var body = {
        ge: ge,
    };
    var target = APIPaths.apiEndPoint.concat(APIPaths.getParliamentOverall);

    return callAPI(body, target, {
        status: APIResult.TIME_OUT,
        status_message: "-",
        t: [],
    });
}
