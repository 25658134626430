import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { initializeFirestore } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { de } from "./Utilities";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAYGo98S0n4Is776Yko9cNZMXcuiTGaytw",
  authDomain: "asia-tv-dfe69.firebaseapp.com",
  projectId: "asia-tv-dfe69",
  storageBucket: "asia-tv-dfe69.appspot.com",
  messagingSenderId: "651654636332",
  appId: "1:651654636332:web:ef04abbf562319fcc24289",
  measurementId: "G-TL7SY8CKJ3",
};

const firebaseApp = initializeApp(firebaseConfig);
const firestoreDB = initializeFirestore(firebaseApp, {
  experimentalForceLongPolling: true,
});
const auth = getAuth(firebaseApp);
let i =
  "Vm0wd2QyUXlVWGxWV0d4WFlUSm9WMVl3Wkc5V1ZsbDNXa2M1YWxKc1dqQlVWbU0xVmpGYWMySkVUbGhoTWsweFZqQmFTMk15U2tWVWJHaG9UVmhDVVZadGVGWmxSbGw1VTJ0V1ZXSkhVbkJXYTFwaFpWWmtWMXBJY0d4U2JHdzBWMnRvUjFWdFNrZFhiR2hhWVRGYU0xWnNXbUZrUlRGWlkwZDRVMkpXU2twV2JURXdZVEpHVjFOWVpGaGlSa3BZV1ZkMFlWUkdWWGhYYlVacVZtczFlRlpYZUhkV01ERldZMFZ3VjJKVVJYZFpla3BIWXpGT2RWWnNTbWxTTW1oWlYxWmtNRkl3TUhoalJscFlZbGhTV0ZSV1dtRmxWbFY1VFZSU1ZrMXJjRmhWTW5SelZqSktWVkZZYUZkU1JWcHlWVEJhVDJOc2NFaGpSazVwVmpKb1dGWnRNVEJXTVd4WFZXdGtXR0pIVWxsWmJGWmhZMVpzY21GRlRsTmlSbkJaV2xWYVQyRkdXbk5qU0d4WFRWWktSRlpxUVhoa1ZsWjFWMnhhYkdFelFrbFdiWEJIVkRKU1YxWnVUbGhpVjNodlZGVm9RMWRXV1hoWGJYUk9VakZHTlZaWE5VOVhSMHB5VGxac1dtSkhhRlJXTUZwVFZqRndSMVJ0ZUdsU2JYY3hWa1phVTFVeFduSk5WVlpUWVRGd1dGbHNhRU5oUmxweFVWaG9hMVpzV2pGV01uaHJZVWRGZWxGcmJGZGlXRUpJVmtSS1UxWXhaSFZVYkZKcFZqSm9lbGRYZUc5aU1XUkhWMjVTVGxKRlduSlVWbHB6VGtaYWRHUkhkRmRpVlhCNVdUQmFjMWR0U2toaFJsSmFUVlp3ZWxreWVHdGtWbkJHVGxaT2FXRXdjRWxXYlhCS1pVVXhSMWRzYUZSaE1sSnhWVEJvUTFsV1VsWlhhM1JvVW14d2VGVnRNVWRoTVVwelYyeGFWbFl6YUhKWlZXUkdaVWRPUjFac2FGaFRSVXBKVm10U1MxUXlUWGhhU0ZaVllrWktjRlZxUmt0V1ZscEhWV3RLYTAxRVJsTlZSbEYzVUZFOVBRPT0=";
let p =
  "Vm0wd2QyUXlVWGxWV0d4WFlUSm9WMVl3Wkc5V1ZsbDNXa2M1YWxKc1dqQlVWbU0xVmpGYWMySkVUbGhoTWsweFZqQmFTMk15U2tWVWJHaG9UVmhDVVZadGVGWmxSbGw1VTJ0V1ZXSkhVazlaYlhoSFRURmFkR05GZEZSTlZUVkpWbTEwYTFkSFNrZGpSVGxhWWxoU1RGWnNXbUZqVmtaMFVteFNUbUpGY0VwV2JURXdZekpHYzFOdVVtaFNlbXhXVm0xNFlVMHhXbk5YYlhSWFRWaENSbFpYZUZOVWJVWTJVbFJDVjAxdVVuWldha3BIVWpGT2RWUnRjRk5XUjNob1YxZDRiMVV4VWtkWGJrNVlZbGhTV0ZSV2FFTlNiRnBZWlVkMFZXSkdjREZWVjNodlZqRktjMk5HYUZkaGEzQklWVEJhWVdSV1NuTlRiR1JUVFRBd01RPT0";

signInWithEmailAndPassword(auth, de(i), de(p));
export default firestoreDB;
