import React from "react";
import "./NotificationCard.scss";
import UpIcon from "../../images/up_icon.svg";
import DownIcon from "../../images/down_icon.svg";
import OpenInNewIcon from "../../images/open_in_new.svg";
import OpenInNewWhiteIcon from "../../images/open_in_new_white.svg";
import firestoreDB from "../../utilities/Firebase";
import { onSnapshot, query, collection } from "firebase/firestore";
import PropTypes from "prop-types";
import { XMLParser } from "fast-xml-parser";
import queryString from "query-string";

class NotificationCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showNotification: true,
      feeds: [],
      rss: [],
      image: "",
      mobileLayout: false,
      type: 0,
    };

    this.ge = 15;

    this.getRSS = this.getRSS.bind(this);
  }

  componentDidMount() {
    let params = queryString.parse(window.location.search);
    if (params["c"]) {
      try {
        this.ge = parseInt(params["c"]);
      } catch (e) {}
    }
    this.getNotifications();
    this.getRSS();
  }

  async getRSS() {
    let url = `https://atvnewsonline.com/tag/atv_ge15/feed/`;

    let options = {
      headers: {},
    };

    var rssData = null;
    await fetch(url, options)
      .then((res) => res.text())
      .then((data) => (rssData = data));

    let parser = new XMLParser();
    let rssItems = parser.parse(rssData)["rss"]["channel"]["item"];
    var parseItems = [];
    if (rssItems) {
      rssItems.forEach((eachItem) => {
        parseItems.push({
          title: eachItem.title,
          link: eachItem.link,
          message: eachItem.description,
          image: eachItem.thumbnail,
          type: 0,
          timestamp: Math.round(new Date(eachItem.pubDate).getTime() / 1000),
        });
      });
    }
    this.setState({ rss: parseItems });
  }

  convertToMonthDay(epochTime) {
    var date = new Date(epochTime * 1000);
    var formattedDate =
      date.getUTCFullYear() +
      "年" +
      (date.getUTCMonth() + 1) +
      "月" +
      date.getUTCDate() +
      "日";
    return formattedDate;
  }

  formatAMPM(epochTime) {
    var date = new Date(epochTime * 1000);
    var hours = date.getHours();
    var minutes = date.getMinutes();

    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes;
    return strTime;
  }

  async getNotifications() {
    const q = query(
      collection(firestoreDB, `ge${this.ge}`, "live-feeds", "updates")
    );
    onSnapshot(q, (querySnapshot) => {
      const feeds = [];
      querySnapshot.forEach((doc) => {
        var data = doc.data();
        data["id"] = doc.id;
        feeds.push(data);
        this.setState({ feeds: feeds });
      });
    });
  }

  render() {
    return (
      <div
        style={{
          height: "calc(100% - 56px)",
          overflowX: "hidden",
        }}
      >
        <div
          className={`notification-card-parent ${
            this.props.desktopMode ? "" : "mobile-parent"
          }`}
          style={{
            overflowY: "hidden",
            background: this.props.desktopMode ? "white" : "#505050",
            border: this.props.desktopMode
              ? " 1px solid rgb(191, 191, 191)"
              : "none",
            borderBottomLeftRadius: !this.props.desktopMode ? 0 : 12,
            borderBottomRightRadius: !this.props.desktopMode ? 0 : 12,
            paddingTop: 16,
            height: "100%",
            boxSizing: "border-box",
            animation: "notification-height 0.25s",
          }}
        >
          <div className="hori" style={{ justifyContent: "center" }}>
            {!this.props.desktopMode ? (
              <div style={{ opacity: 0, width: 24 }} src={""}></div>
            ) : null}
            {this.props.desktopMode ? null : (
              <div className="mobile-feed-title">选战焦点</div>
            )}
            {!this.props.desktopMode ? (
              <img
                alt="noti"
                height="24px"
                style={{
                  cursor: "pointer",
                  background: "#505050",
                  zIndex: 200,
                  borderTopLeftRadius: "1em",
                  borderTopRightRadius: "1em",
                }}
                src={this.state.showNotification ? DownIcon : UpIcon}
                onClick={() => {
                  //   this.setState({
                  //     showNotification: !this.state.showNotification,
                  //   });
                  this.props.disableMobile();
                }}
              />
            ) : null}
          </div>
          <div className="overflow-feeds" id="style-2">
            {this.state.rss
              .concat(this.state.feeds)
              .sort((a, b) => {
                if (b.timestamp === a.timestamp) {
                  return 0;
                }
                if (b.timestamp > a.timestamp) {
                  return -1;
                } else {
                  return 1;
                }
              })
              .slice(
                !this.props.desktopMode
                  ? this.state.showNotification
                    ? 0
                    : this.state.feeds.length + this.state.rss.length - 1
                  : 0
              )
              .reverse()
              .map((feed, index) => {
                return (
                  <div
                    style={{
                      padding: 4,
                      animation: "fade-in 0.35s",
                    }}
                    key={index}
                  >
                    {!this.props.desktopMode ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            padding: 8,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              marginBottom: "0.5em",
                            }}
                          >
                            <div className="notification-circle" />
                            <div
                              className="notification-card-timestamp"
                              style={{
                                color: this.props.desktopMode ? "black" : "",
                              }}
                            >
                              {this.convertToMonthDay(feed.timestamp)}{" "}
                              {this.formatAMPM(feed.timestamp)}
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {this.state.showNotification && feed.image ? (
                              <img
                                alt="feed"
                                onClick={() => {
                                  if (feed.link) {
                                    window.open(
                                      feed.link,
                                      "_blank",
                                      "noopener,noreferrer"
                                    );
                                  }
                                }}
                                style={{
                                  maxHeight: "200px",
                                  width: "100%",
                                  aspectRatio: "16/9",
                                  objectFit: "cover",
                                  paddingBottom: 10,
                                  borderRadius: 10,
                                  cursor: "pointer",
                                }}
                                src={feed.image}
                              />
                            ) : null}
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                className="notification-card-title"
                                onClick={() => {
                                  if (this.state.showNotification) {
                                    if (feed.link) {
                                      window.open(
                                        feed.link,
                                        "_blank",
                                        "noopener,noreferrer"
                                      );
                                    }
                                  }
                                }}
                                style={{
                                  color: this.props.desktopMode ? "black" : "",
                                  paddingTop: 16,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                              >
                                {feed.type === 1 ? "[Sponsored] " : null}
                                {feed.title}
                                {feed.link && this.state.showNotification ? (
                                  <img
                                    alt="open new tab icon"
                                    src={OpenInNewWhiteIcon}
                                    style={{
                                      width: 16,
                                      height: 16,
                                      marginLeft: 8,
                                    }}
                                  />
                                ) : null}
                              </div>
                              {this.state.showNotification ? (
                                <div
                                  className="notification-card-description"
                                  style={{
                                    color: this.props.desktopMode
                                      ? "black"
                                      : "",
                                    paddingTop: 16,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                  }}
                                >
                                  {feed.message}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        {this.state.showNotification ? (
                          <div>
                            {index !==
                            this.state.feeds.length +
                              this.state.rss.length -
                              1 ? (
                              <hr
                                style={{
                                  border: this.props.desktopMode
                                    ? "1px solid #BFBFBF"
                                    : "1px solid rgba(255, 255, 255, 0.25)",
                                  boxSizing: "border-box",
                                }}
                              ></hr>
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {feed.image !== "" ? (
                          <img
                            alt="noti"
                            className="notification-cover-img"
                            src={feed.image}
                            onClick={() => {
                              if (feed.link) {
                                window.open(
                                  feed.link,
                                  "_blank",
                                  "noopener,noreferrer"
                                );
                              }
                            }}
                          />
                        ) : (
                          ""
                        )}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <div className="notification-circle" />
                          <div
                            className="notification-card-timestamp"
                            style={{
                              color: this.props.desktopMode ? "black" : "",
                            }}
                          >
                            {this.convertToMonthDay(feed.timestamp)}{" "}
                            {this.formatAMPM(feed.timestamp)}
                          </div>
                        </div>

                        <div
                          className="notification-card-title"
                          onClick={() => {
                            if (feed.link) {
                              window.open(
                                feed.link,
                                "_blank",
                                "noopener,noreferrer"
                              );
                            }
                          }}
                          style={{
                            cursor: feed.link ? "pointer" : "unset",
                            color: this.props.desktopMode ? "black" : "",
                          }}
                        >
                          {feed.type === 1 ? "[Sponsored] " : null}
                          {feed.title}
                          {feed.link ? (
                            <img
                              alt="open new tab icon"
                              src={OpenInNewIcon}
                              style={{
                                width: 16,
                                height: 16,
                                marginLeft: 8,
                                cursor: "pointer",
                              }}
                            />
                          ) : null}
                        </div>

                        <div
                          className="notification-card-description"
                          style={{
                            color: this.props.desktopMode ? "black" : "",
                          }}
                        >
                          {feed.message}
                        </div>

                        {index !==
                        this.state.feeds.length + this.state.rss.length - 1 ? (
                          <hr
                            style={{
                              border: this.props.desktopMode
                                ? "1px solid #BFBFBF"
                                : "1px solid rgba(255, 255, 255, 0.25)",
                              boxSizing: "border-box",
                            }}
                          ></hr>
                        ) : null}
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}

NotificationCard.propTypes = {
  desktopMode: PropTypes.bool,
};

NotificationCard.defaultProps = {
  desktopMode: true,
};

export default NotificationCard;
