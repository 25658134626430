import React from "react";
import "./TableInfo.scss";
import PropTypes from "prop-types";
import PollTag from "./PollTag";
import DownIcon from "../../images/arrow_down.svg";
import RightIcon from "../../images/arrow_right.svg";

class TableInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      limit: true,
      showMore: false,
      detailsInfo: [],
      currentDetailsOpen: [1, 2, 3, 4, 5, 6],
    };
    this.total = 0;
    this.parties = this.props.parties;
    this.alliances = this.props.alliances;
  }

  componentDidMount() {}

  getPartyInfo(partyID) {
    for (var i = 0; i < this.props.parties.length; i++) {
      if (this.props.parties[i].party_id === partyID) {
        return this.props.parties[i];
      }
    }
    return null;
  }
  getAllianceData(partyID) {
    var data = this.getAllianceInfo(this.getAllianceIDWithPartyID(partyID));
    return data;
  }
  getAllianceInfo(allianceID) {
    for (var i = 0; i < this.props.alliances.length; i++) {
      if (this.props.alliances[i].alliance_id === allianceID) {
        return this.props.alliances[i];
      }
    }
    return null;
  }

  getAllianceName(allianceID) {
    var allianceData = this.getAllianceInfo(allianceID.toString());
    if (allianceData !== null && allianceData !== undefined) {
      return allianceData["name_cn"];
    }
    return "-";
  }

  getPartyColor(partyID) {
    var allianceData = this.getAllianceData(partyID.toString());
    if (allianceData !== null && allianceData !== undefined) {
      return `#${allianceData["color"]}A6`;
    }
    return "#000000";
  }

  getPartyName(partyID) {
    var partyData = this.getPartyInfo(partyID.toString());
    if (partyData !== null && partyData !== undefined) {
      return partyData["name_cn"];
    }
  }

  getSortedData() {
    var data = [...this.props.parliamentData];
    data.sort((a, b) => {
      return b.win - a.win;
    });
    // if (this.state.limit) {
    //     data = data.slice(0, 5);
    // }
    return data;
  }

  getTotal() {
    var total = 0;
    this.props.parliamentData.forEach((eachParliamentData) => {
      total += eachParliamentData.win;
    });

    return total;
  }

  getData() {
    var partyList = [];
    var banList = [];
    var sortedData = [];
    for (let i = 0; i < this.props.parties.length; i++) {
      if (this.getSortedData[i] === undefined) {
        banList.push(this.getSortedData[i]);
      } else {
        partyList.push(this.getSortedData[i]);
      }
    }

    sortedData = this.props.alliances;
    sortedData.forEach((e) => {
      e.partyList = [];
      e.showMore = false;
    });

    for (let j = 0; j < partyList.length; j++) {
      for (let i = 0; i < this.props.alliances.length; i++) {
        if (
          partyList[j].alliance_id ===
          parseInt(this.props.alliances[i].alliance_id)
        ) {
          sortedData[i].partyList.push(partyList[j]);
        }
      }
    }

    for (let j = 0; j < this.getSortedData().length; j++) {
      for (let i = 0; i < this.props.alliances.length; i++) {
        if (
          parseInt(this.getSortedData()[j].info.alliance.alliance_id) ===
          parseInt(this.props.alliances[i].alliance_id)
        ) {
          sortedData[i].partyList.push(this.getSortedData()[j]);
        }
      }
    }

    return sortedData;
  }

  getAllianceIDWithPartyID(partyID = "0") {
    var returnValue = "0";
    if (partyID !== "0") {
      for (var i = 0; i < this.props.parties.length; i++) {
        let eachParty = this.props.parties[i];
        if (eachParty.party_id === partyID) {
          return eachParty.alliance_id;
        }
      }
    }
    return returnValue;
  }

  getAllianceColor(allianceID) {
    var allianceData = this.getAllianceInfo(allianceID.toString());
    if (allianceData !== null && allianceData !== undefined) {
      return `#${allianceData["color"]}`;
    }
    return "#000000";
  }

  render() {
    return (
      <div className="table-info-parent">
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div
            className="poll-tag-parent"
            style={{
              opacity: 0,
            }}
          >
            <PollTag width={140} />
          </div>
          <div className="table-info-header-text table-info-spacer">竞选</div>
          <div className="table-info-header-text table-info-spacer">胜选</div>
        </div>

        {this.getData().map((data, index) => {
          return (
            <div
              key={`table-info-alliance-${index}`}
              className="top-bottom-border"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <div
                  className="poll-tag-parent"
                  style={{
                    background: index % 2 !== 0 ? "#00000030" : "white",
                    borderBottomLeftRadius: index === 5 ? 8 : 0,
                  }}
                >
                  <img
                    style={{
                      height: "30px",
                      cursor: "pointer",
                      animation: this.state.detailsInfo.includes(index)
                        ? "rotate-arrow 0.2s forwards"
                        : "reverse-rotate 0.2s",
                    }}
                    onClick={() => {
                      var detailsInfo = this.state.detailsInfo;
                      if (detailsInfo.includes(index)) {
                        detailsInfo.splice(detailsInfo.indexOf(index), 1);
                      } else {
                        detailsInfo.push(index);
                      }
                      this.setState({
                        showMore: !this.state.showMore,
                        detailsInfo: detailsInfo,
                      });
                    }}
                    alt="show-more-items-icon"
                    src={RightIcon}
                  />
                  <PollTag
                    width={140}
                    color={this.getAllianceColor(data.alliance_id)}
                    label={this.getAllianceName(data.alliance_id)}
                  />
                </div>
                <div
                  className="table-info-number-data table-info-spacer"
                  style={{
                    background: index % 2 !== 0 ? "#00000030" : "white",
                  }}
                >
                  {data.partyList.reduce((n, { contest }) => n + contest, 0)}
                </div>
                <div
                  className="table-info-number-data table-info-spacer"
                  style={{
                    background: index % 2 !== 0 ? "#00000030" : "white",
                    borderBottomRightRadius: index === 5 ? 8 : 0,
                  }}
                >
                  {data.partyList.reduce((n, { win }) => n + win, 0)}
                </div>
              </div>

              {this.state.detailsInfo.includes(index) ? (
                <div>
                  {data.partyList.map((party, index2) => {
                    return (
                      <div
                        key={`table-info-party-list-${index2}`}
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="poll-tag-parent"
                          style={{
                            border: "none",
                            height: "44px",
                            background:
                              index2 % 2 === 0 ? "#00000010" : "white",
                          }}
                        >
                          <img
                            style={{
                              height: "30px",
                              opacity: 0,
                            }}
                            alt="show-more-items-icon"
                            src={
                              (!this.state.showMoreDetails &&
                                this.state.showMore) ||
                              !this.state.detailsInfo.includes(index)
                                ? RightIcon
                                : DownIcon
                            }
                          />
                          <PollTag
                            width={140}
                            color={this.getPartyColor(parseInt(party.party_id))}
                            label={this.getPartyName(parseInt(party.party_id))}
                          />
                        </div>
                        <div
                          className="table-info-number-data table-info-spacer"
                          style={{
                            padding: "0.8em",
                            background:
                              index2 % 2 === 0 ? "#00000010" : "white",
                            width: "35%",
                            height: "auto",
                            border: "none",
                          }}
                        >
                          {party.contest}
                        </div>
                        <div
                          className="table-info-number-data table-info-spacer"
                          style={{
                            padding: "0.8em",
                            background:
                              index2 % 2 === 0 ? "#00000010" : "white",
                            width: "35%",
                            height: "auto",
                            border: "none",
                          }}
                        >
                          {party.win}
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    );
  }
}

TableInfo.propTypes = {
  alliances: PropTypes.array,
  parties: PropTypes.array,
  parliamentData: PropTypes.array,
  limit: PropTypes.bool,
};

TableInfo.defaultProps = {
  limit: true,
};

export default TableInfo;
