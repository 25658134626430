import React from 'react';
import "./StateSeatTableInfo.scss";
import PropTypes from 'prop-types';
import PollTag from './PollTag';
import {InfinitySpin} from 'react-loader-spinner';

class StateSeatTableInfo extends React.Component {

    componentDidMount() {}

    getPartyInfo(partyID) {
        for (var i = 0; i < this.props.parties.length; i++) {
            if (this.props.parties[i].party_id === partyID) {
                return this.props.parties[i];
            }
        }
        return null;
    }

    getAllianceData(partyID) {
        return this.getAllianceInfo(this.getAllianceIDWithPartyID(partyID));
    }

    getAllianceInfo(allianceID) {
        for (var i = 0; i < this.props.alliances.length; i++) {
            if (this.props.alliances[i].alliance_id === allianceID) {
                return this.props.alliances[i];
            }
        }
        return null;
    }

    getAllianceIDWithPartyID(partyID = "0") {
        var returnValue = "0";
        if (partyID !== "0") {
            for (var i = 0; i < this.props.parties.length; i++) {
                let eachParty = this.props.parties[i];
                if (eachParty.party_id === partyID) {
                    return eachParty.alliance_id;
                }
            }
        }
        return returnValue;
    }

    getPartyColor(partyID) {
        var allianceData = this.getAllianceData(partyID.toString());
        if (allianceData !== null && allianceData !== undefined) {
            return `#${allianceData["color"]}`
        }
        return "#000000";
    }

    getPartyName(partyID) {
        var partyData = this.getPartyInfo(partyID.toString());
        if (partyData !== null && partyData !== undefined) {
            return partyData["name_cn"];
        }
        return "-";
    }

    getParliamentWin()
    {
        var total = 0;
        if (this.props.overallData) {
            Object
                .keys(this.props.overallData)
                .forEach((eachParty) => {
                    total += this.props.overallData[eachParty]
                        ? this.props.overallData[eachParty].par_win
                        : 0;
                })
        }
        return total;
    }

    getStateWin()
    {
        var total = 0;
        if (this.props.overallData) {
            Object
                .keys(this.props.overallData)
                .forEach((eachParty) => {
                    total += this.props.overallData[eachParty]
                        ? this.props.overallData[eachParty].state_win
                        : 0;
                })
        }
        return total;
    }

    sortedData()
    {
        var data = {};
        Object.keys(this.props.overallData).forEach((eachParty) => {
            data[eachParty] = this.props.overallData[eachParty]["par_win"];
        });
        
        let dataToPopulate = Object.keys(data).sort(function(a,b){return data[b]-data[a]});

        return dataToPopulate;
    }

    render() {
        return (
            <div className='table-block-parent'>
                <div className='table-info-parent'>
                    <div className='full-table-header'>
                        <div
                            style={{
                            marginRight: "auto"
                        }}>
                            <div className='table-header'>
                                <div
                                    className="circle"
                                    style={{
                                    background: "#EA564B",
                                    opacity: 0
                                }}>州</div>
                                <div
                                    style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    flex: 1
                                }}>
                                    <div>
                                        <div
                                            style={{
                                            display: 'flex',
                                            flexDirection: "row"
                                        }}>
                                            <div
                                                className='pad header-text'
                                                style={{
                                                opacity: 0
                                            }}>
                                                竞选
                                            </div>
                                            <div
                                                className='pad header-text'
                                                style={{
                                                opacity: 0
                                            }}>
                                                胜选
                                            </div>
                                        </div>
                                        {this.sortedData().map((partyID, index) => {
                                            return <div
                                                key={`${index}-table1`}
                                                style={{
                                                background: index % 2 === 0
                                                    ? "rgba(70, 70, 70, 0.1)"
                                                    : "white"
                                            }}
                                                className='table-block'>
                                                <div
                                                    style={{
                                                    padding: index === 0
                                                        ? "0.2em"
                                                        : "0.22em"
                                                }}>
                                                    <PollTag
                                                        width={140}
                                                        color={this.getPartyColor(partyID)}
                                                        label={this.getPartyName(partyID)}/>
                                                </div>
                                            </div>
                                        })}
                                        <div
                                            style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            borderTop: "1px solid #DFDFDF"
                                        }}>
                                            <div className="number-data pad">总计</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='table-header'>
                            <div
                                style={{
                                display: "flex",
                                justifyContent: "center"
                            }}>
                                <div
                                    className="circle"
                                    style={{
                                    background: "#464646"
                                }}>国</div>
                            </div>
                            <div
                                style={{
                                display: "flex",
                                flexDirection: "column"
                            }}>
                                <div>
                                    <div
                                        style={{
                                        display: 'flex',
                                        flexDirection: "row",
                                        justifyContent: "space-around"
                                    }}>
                                        <div className='pad header-text'>
                                            竞选
                                        </div>
                                        <div className='pad header-text'>
                                            胜选
                                        </div>
                                    </div>
                                    {this.sortedData().map((partyID, index) => {
                                        return <div
                                            key={`${index}-table2`}
                                            className='table-block'
                                            style={{
                                            background: index % 2 === 0
                                                ? "rgba(70, 70, 70, 0.1)"
                                                : "white"
                                        }}>
                                            <div className='pad number-data' style={{}}>
                                                {this.props.overallData[partyID].par_contest}
                                            </div>
                                            <div className='pad number-data'>
                                                {this.props.overallData[partyID].par_win}
                                            </div>
                                        </div>
                                    })}
                                    <div
                                        style={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                        borderTop: "1px solid #DFDFDF",
                                        borderRight: "1px solid #DFDFDF"
                                    }}>
                                        <div
                                            className='pad number-data'
                                            style={{
                                            opacity: 0
                                        }}>
                                            0
                                        </div>
                                        <div className='pad number-data'>
                                            {this.getParliamentWin()}
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className='table-header'>
                            <div
                                style={{
                                display: "flex",
                                justifyContent: "center"
                            }}>
                                <div
                                    className="circle"
                                    style={{
                                    background: "#EA564B"
                                }}>州</div>
                            </div>
                            <div
                                style={{
                                display: "flex",
                                flexDirection: "column"
                            }}>
                                <div>
                                    <div
                                        style={{
                                        display: 'flex',
                                        flexDirection: "row",
                                        justifyContent: "space-around"
                                    }}>
                                        <div className='pad header-text'>
                                            竞选
                                        </div>
                                        <div className='pad header-text'>
                                            胜选
                                        </div>
                                    </div>
                                    {this.sortedData().map((partyID, index) => {
                                        return <div
                                            key={`${index}-table3`}
                                            className='table-block'
                                            style={{
                                            background: index % 2 === 0
                                                ? "rgba(70, 70, 70, 0.1)"
                                                : "white"
                                        }}>
                                            <div className='pad number-data' style={{}}>
                                                {this.props.overallData[partyID].state_contest}
                                            </div>
                                            <div className='pad number-data'>
                                                {this.props.overallData[partyID].state_win}
                                            </div>
                                        </div>
                                    })}
                                    <div
                                        style={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                        borderTop: "1px solid #DFDFDF"
                                    }}>
                                        <div
                                            className='pad number-data'
                                            style={{
                                            opacity: 0
                                        }}></div>
                                        <div className='pad number-data'>
                                            {this.getStateWin()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.isLoading
                    ? (
                        <div className='blocker'>
                            <InfinitySpin width='200' color="white"/>
                        </div>
                    )
                    : null}
            </div>
        );
    }
}

StateSeatTableInfo.propTypes = {
    currentState: PropTypes.number,
    parties: PropTypes.array,
    alliances: PropTypes.array,
    overallData: PropTypes.object,
    isLoading: PropTypes.bool
};

StateSeatTableInfo.defaultProps = {
    currentState: 0,
    parties: {},
    alliances: {},
    overallData: {},
    isLoading: false
};

export default StateSeatTableInfo;