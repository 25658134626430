import React from "react";
import "./MainPage.scss";
import "../widgets/NotificationCard.scss";
import "./FeedsPage.scss";
import OpenInNewWhiteIcon from "../../images/open_in_new_white.svg";
import firestoreDB from "../../utilities/Firebase";
import { onSnapshot, query, collection } from "firebase/firestore";
import { XMLParser } from "fast-xml-parser";
import queryString from "query-string";

class FeedsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = { feeds: [], rss: [] };
    this.ge = 15;
    this.timeAfter = 0;
  }

  componentDidMount() {
    let params = queryString.parse(window.location.search);
    if (params["c"]) {
      try {
        this.ge = parseInt(params["c"]);
        this.timeAfter = parseInt(params["t"]) > 0 ? parseInt(params["t"]) : 0;
      } catch (e) {}
    }
    window.addEventListener("resize", this.checkHeight);
    this.getNotifications();
    this.getRSS();

    setInterval(this.checkHeight, 3000);
  }

  async getRSS() {
    let url = `https://atvnewsonline.com/tag/atv_ge15/feed/`;

    let options = {
      headers: {},
    };

    var rssData = null;
    await fetch(url, options)
      .then((res) => res.text())
      .then((data) => (rssData = data));

    let parser = new XMLParser();
    let rssItems = parser.parse(rssData)["rss"]["channel"]["item"];
    var parseItems = [];
    if (rssItems) {
      rssItems.forEach((eachItem) => {
        parseItems.push({
          title: eachItem.title,
          link: eachItem.link,
          message: eachItem.description,
          image: eachItem.thumbnail,
          type: 0,
          timestamp: Math.round(new Date(eachItem.pubDate).getTime() / 1000),
        });
      });
    }
    this.setState({ rss: parseItems });
  }

  convertToMonthDay(epochTime) {
    var date = new Date(epochTime * 1000);
    var formattedDate =
      date.getUTCFullYear() +
      "年" +
      (date.getUTCMonth() + 1) +
      "月" +
      date.getUTCDate() +
      "日";
    return formattedDate;
  }

  formatAMPM(epochTime) {
    var date = new Date(epochTime * 1000);
    var hours = date.getHours();
    var minutes = date.getMinutes();

    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes;
    return strTime;
  }

  async getNotifications() {
    const q = query(
      collection(firestoreDB, `ge${this.ge}`, "live-feeds", "updates")
    );
    onSnapshot(q, (querySnapshot) => {
      const feeds = [];
      querySnapshot.forEach((doc) => {
        var data = doc.data();
        data["id"] = doc.id;
        data["type"] = "fbfeed";
        feeds.push(data);
        this.setState({ feeds: feeds });
      });
    });
    //this.checkHeight();
  }

  checkHeight(from = 1) {
    var body = document.getElementById("feeds-body");
    if (body) {
      if (body.clientHeight > 0) {
        if (window.parent) {
          //console.log("pushing height " + body.clientHeight.toString());
          window.parent.postMessage(body.clientHeight.toString(), "*");
        }
      }
    }
  }

  render() {
    return (
      <div id="feeds-body" onLoad={this.checkHeight(2)}>
        <div className="overflow-feeds" id="style-2">
          {this.state.rss
            .concat(this.state.feeds)
            .sort((a, b) => {
              if (b.timestamp === a.timestamp) {
                return 0;
              }
              if (b.timestamp > a.timestamp) {
                return -1;
              } else {
                return 1;
              }
            })
            .reverse()
            .map((feed, index) => {
              //console.log("fts : " + feed.timestamp + " , timeafter: " + this.timeAfter);
              
              return ((feed.type == "fbfeed" && parseInt(feed.timestamp) > this.timeAfter) || (feed.type == 0 && parseInt(feed.timestamp) > this.timeAfter)) ? (
                
                <div
                  style={{
                    padding: 4,
                    margin: "16px 8px 16px 8px",
                    animation: "fade-in 0.35s",
                    borderRadius: 12,
                    boxShadow: "0px 0px 12px 0px #00000020",
                  }}
                  key={index}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        padding: 8,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginBottom: "0.5em",
                        }}
                      >
                        <div className="notification-circle" />
                        <div
                          className="feed-card-timestamp"
                          style={{
                            color: this.props.desktopMode ? "black" : "",
                          }}
                        >
                          {this.convertToMonthDay(feed.timestamp)}{" "}
                          {this.formatAMPM(feed.timestamp)}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {feed.image ? (
                            <img
                              alt="feed"
                              onClick={() => {
                                if (feed.link) {
                                  window.open(
                                    feed.link,
                                    "_blank",
                                    "noopener,noreferrer"
                                  );
                                }
                              }}
                              style={{
                                maxHeight: 256,
                                width: "100%",
                                aspectRatio: "16/9",
                                objectFit: "cover",
                                marginBottom: 10,
                                borderRadius: 10,
                                cursor: "pointer",
                              }}
                              src={feed.image}
                            />
                          ) : (
                            ""
                          )}
                          <div
                            className="feed-card-title"
                            onClick={() => {
                              if (feed.link) {
                                window.open(
                                  feed.link,
                                  "_blank",
                                  "noopener,noreferrer"
                                );
                              }
                            }}
                            style={{
                              color: this.props.desktopMode ? "black" : "",
                              paddingTop: 16,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              paddingRight: 0,
                              cursor: "pointer",
                            }}
                          >
                            {feed.type === 1 ? "[Sponsored] " : null}
                            {feed.title}
                            {feed.link && this.state.showNotification ? (
                              <img
                                alt="open new tab icon"
                                src={OpenInNewWhiteIcon}
                                style={{
                                  width: 16,
                                  height: 16,
                                  marginLeft: 8,
                                }}
                              />
                            ) : null}
                          </div>
                          <div
                            className="feed-card-description"
                            style={{
                              color: this.props.desktopMode ? "black" : "",
                              paddingTop: 16,
                              paddingLeft: 0,
                              paddingRight: 0,
                            }}
                          >
                            {feed.message}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            
               
              ) : ""
              
            })}
        </div>
      </div>
    );
  }
}

export default FeedsPage;
